import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import custom from "../../assets/css/login.module.scss";
import { HiOutlineHome } from "react-icons/hi";
import Logo from "../../assets/img/common/logo.png";
import { LoginHeader } from "../../components/Login/LoginHeader";
import { TERMS_DATA } from "../../utils/TermsData";
import Terms from "../../components/Login/Terms";
import { Bootpay } from "@bootpay/client-js";
import { NormalReqApi } from "../../utils/ReqApi";
import { useRecoilState } from "recoil";
import { authSelect } from "../../recoil/AtomConfig";
import styled from "styled-components";

const CustomGroup = styled.div`
  > label {
    display: block;
    font-size: 0.75em;
    color: gray;
  }
  > input {
    width: 100%;
    border: none;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 0.5em 1em;
    font-size: 0.75em;
    &:focus {
      outline: 1px solid gray;
    }
  }
`;

const APID = process.env.REACT_APP_APID;
const AUTHID = process.env.REACT_APP_AUTHID;
const phoneRegExp = /^[0-9]{8,}$/;

const SignUp = () => {
  const [auth, setAuth] = useRecoilState(authSelect);
  const [terms, setTerms] = useState([]);
  const phoneRef = useRef(null);
  const navi = useNavigate();
  const handleValid = async () => {
    const phone = phoneRef.current.value;
    if (!phone) {
      alert("인증할 휴대전화번호를 입력해주시기 바랍니다.");
      return false;
    }
    if (!phoneRegExp.test(phone)) {
      alert("휴대전화번호를 확인해주시기 바랍니다.");
      return false;
    }

    let termsPass = true;
    terms.map((item, index) => {
      if (item.required && !item.checked) {
        // alert("필수 약관에 동의해주시기 바랍니다.");
        termsPass = false;
      }
    });
    if (!termsPass) {
      alert("필수 약관에 동의해주시기 바랍니다.");
      return false;
    }

    const emailYn = terms.filter((f) => f.id === 3)[0].checked;

    try {
      const response = await Bootpay.requestAuthentication({
        application_id: APID,
        pg: "다날",
        order_name: "본인인증",
        authentication_id: AUTHID,
        user: {
          phone: phone,
        },
      });

      switch (response.event) {
        case "done":
          const { receipt_id, status } = response.data;
          if (status == 12) {
            const authResult = await NormalReqApi(
              "get",
              `/bp/auth/${receipt_id}/${phone}`
            );
            if (!authResult.flag) {
              alert("이미 가입되어있는 사용자 입니다.");
              return false;
            } else {
              setAuth((prev) => {
                const temp = { ...prev };
                temp.birth = authResult.data.birth;
                temp.di = authResult.data.di;
                temp.gender = authResult.data.gender;
                temp.name = authResult.data.name;
                temp.phone = phone;
                temp.unique = authResult.data.unique;
                temp.emailYn = emailYn;
                return { ...temp };
              });
              navi("/signupnormal");
            }
          }
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
      console.log(e.message);
      switch (e.event) {
        case "cancel":
          // 사용자가 결제창을 닫을때 호출
          console.log(e.message);
          break;
        case "error":
          // 결제 승인 중 오류 발생시 호출
          console.log(e.error_code);
          break;
      }
    }
  };

  useEffect(() => {
    setTerms([...TERMS_DATA.filter((f) => f.type === "normal")]);
  }, []);

  return (
    <LoginHeader>
      <div className={custom.join_box}>
        <div className={custom.tab_area}>
          <ul>
            <li className={custom.active}>
              <a>개인회원</a>
            </li>
            <li>
              <Link to={`/signupcomp`}>기업회원</Link>
            </li>
          </ul>
        </div>
        <div className={custom.tab_content}>
          <div className={custom.terms_area}>
            <h2>대티즌 회원 약관동의</h2>
            <Terms terms={terms} setTerms={setTerms} />
          </div>
          <CustomGroup>
            <label>
              휴대전화번호<small>("-"을 제외하고 입력해주시기 바랍니다)</small>
            </label>
            <input type="text" ref={phoneRef}></input>
          </CustomGroup>

          <div className={custom.btn_areaLogin + " " + custom.join}>
            <button
              className={custom.btn + " " + custom.join + " " + "btn join"}
              type="button"
              onClick={() => handleValid()}
            >
              본인인증
            </button>
          </div>
        </div>
      </div>
    </LoginHeader>
  );
};

export default SignUp;

import styled from "styled-components";
import { DContentsSubMenu } from "../../components/DContents/Common/Element";
import { useState } from "react";
import LogoW from "../../assets/img/common/logo_w.png";
import { Link } from "react-router-dom";
import { NFTBodyWrap, NFTSubVisualDiv } from "./style/nftStyle";
import { NFTSubVisual } from "./components/nftCommon";
import useUserState from "../../components/hook/accountHook";
import { useQuery } from "react-query";
import { ClientOptionGet } from "../../utils/ClientAuth";
import { AWS_ENDPOINT } from "../../utils/commonVariable";
import { useEffect } from "react";
import { useRef } from "react";
import { useMemo } from "react";

/* main index */
const NftGallery = () => {
  const [curCg, setCurCg] = useState({
    cg: "all",
    toggle: false,
  });

  const { data, isLoading } = useQuery(
    ["nftCategory"],
    async () => await ClientOptionGet(`/nftg/category`),
    { retry: false, retryOnMount: false, suspense: false }
  );

  const changeCg = (code) => {
    setCurCg({
      cg: code,
      toggle: true,
    });
  };

  return (
    <div className={"container sub"}>
      <NFTSubVisual items={data?.data} curCg={curCg} changeCg={changeCg} />
      <NFTBody category={curCg} setCategory={setCurCg} />
    </div>
  );
};

// body
const NFTBody = ({ category, setCategory }) => {
  const isReady = true;
  const nextLoadCatcherRef = useRef(null);

  const [lastIdx, setLastIdx] = useState(null);
  const [list, setList] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const { data, isLoading } = useQuery(
    ["nftGallery", category.cg, lastIdx],
    async () =>
      await ClientOptionGet(`/nftg/list?cg=${category.cg}&lastIdx=${lastIdx}`),
    { retry: false, suspense: false, retryOnMount: false }
  );

  useEffect(() => {
    const checkDupli = (elements, target) => {
      if (elements._id === target._id) {
        return true;
      }
    };
    if (isLoading) return;
    const newToList = data.data.filter(
      (f) => !list.some((element) => checkDupli(element, f))
    );
    if (!category.toggle) {
      if (data?.data.length > 0) {
        if (newToList.length > 0) {
          setList([...list, ...newToList]);
        }
      }
    } else {
      setPageLoading(true);
      if (newToList.length > 0) {
        setList([...newToList]);
      } else {
        setList([...newToList]);
      }
      setLastIdx(null);
      setCategory({ ...category, toggle: false });
    }
    setPageLoading(false);

    if (!nextLoadCatcherRef.current) return;

    const io = new IntersectionObserver((e, o) => {
      if (e[0].isIntersecting && data?.data?.length === 8) {
        // setPage(page + 1);
        setLastIdx(data.data[data.data.length - 1]._id);
      }
    });
    io.observe(nextLoadCatcherRef.current);

    return () => {
      io.disconnect();
    };
  }, [data, isLoading]);

  const renderSkeleton = () =>
    [0, 1, 2, 3].map((n, i) => (
      <div key={`nftb_sk_${i}`}>
        <div className={"nftb_img skeleton"}>
          <img src={LogoW} />
        </div>
        <div>
          <p className={"nftb_text title skeleton"}>&nbsp;</p>
          <p className={"nftb_text author skeleton"}>&nbsp;</p>
          <p className={"nftb_text summary skeleton"}>&nbsp;</p>
        </div>
      </div>
    ));

  const loopItem = (_list) =>
    _list.map(
      (
        {
          title,
          description,
          _id,
          endpoint,
          request: {
            author: { name },
          },
        },
        i
      ) => (
        <Link
          key={`nftb_${_id}`}
          to={`/nftg/detail/${_id}`}
          className={"nftItem"}
        >
          <div className={"nftb_img"}>
            <img src={AWS_ENDPOINT.W280 + endpoint.replaceAll("origin/", "")} />
          </div>
          <div>
            <p className={"nftb_text title"}>{title}</p>
            <p className={"nftb_text author"}>{name}</p>
            <p className={"nftb_text summary"}>{description}</p>
          </div>
        </Link>
      )
    );

  const renderItem = useMemo(() => {
    return loopItem(list);
  }, [list]);

  return (
    <NFTBodyWrap>
      <section className={"bodyTop"}>
        {/* button */}
        <Link to={"/nftg/form"}>NFT 발행 신청</Link>
      </section>
      <section className={"nftBody"}>
        <div className={"nftItems"}>
          {!pageLoading ? renderItem : renderSkeleton()}
        </div>
      </section>
      <div
        ref={nextLoadCatcherRef}
        style={{ position: "absolute", bottom: "550px" }}
      />
    </NFTBodyWrap>
  );
};

export default NftGallery;

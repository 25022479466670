import { useState } from "react";
import { useParams } from "react-router-dom";
import { NFTSubVisual } from "./components/nftCommon";
import { NftDetailWrap } from "./style/nftStyle";
import DefaultImg from "../../assets/img/common/img_posterDefault_280.jpg";
import { useQuery } from "react-query";
import { ClientOptionGet, ClientReqGet } from "../../utils/ClientAuth";
import useUserState from "../../components/hook/accountHook";
import DOMPurify from "dompurify";
import { AWS_ENDPOINT } from "../../utils/commonVariable";
import { HiCheckCircle } from "react-icons/hi";

const NFTDetail = () => {
  const { id } = useParams();
  const { id: userId, account, toggle } = useUserState();
  const purify = DOMPurify.sanitize;

  const { data: categories, isLoading } = useQuery(
    ["nftCategory"],
    async () => await ClientOptionGet(`/nftg/category`),
    { retry: false, retryOnMount: false, suspense: false }
  );

  const { data: userInfo, isLoading: userInfoLoading } = useQuery(
    ["userNftInfo", id, account, toggle],
    async () =>
      await ClientReqGet(
        `/nftg/list/${id}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false, suspense: false, retryOnMount: false }
  );

  // todo : api 응답 후 index 반영 필요
  const [curFIndex, setCurFIndex] = useState("all");
  const [loading, setLoading] = useState(true);

  const renderInit = () => (
    <div className={"contents"}>
      <section className={"nftMain"}>
        <div className={"imgWrap"}>
          <img src={DefaultImg} />
        </div>
        <div className={"infoWrap"}>
          <div className={"infoTop"}>
            <span className={"title"}>꼬까도요 세밀화</span>
            <span className={"host"}>해양생물 콘텐츠 공모전</span>
          </div>
          <div className={"infoBottom"}>
            <ul>
              <li>
                <span className={"label"}>보유자</span>
                <p className={"value"}>진세빈</p>
              </li>
              <li>
                <span className={"label"}>토큰 ID</span>
                <p className={"value"}>2345</p>
              </li>
              <li>
                <span className={"label"}>컨트랙트 주소</span>
                <p className={"value"}>2345</p>
              </li>
              <li>
                <span className={"label"}>토큰 표준</span>
                <p className={"value"}>ERC721</p>
              </li>
            </ul>
            <button className={"reqButton"}>거래 제안하기</button>
          </div>
        </div>
      </section>
      <section className={"nftSummary"}>
        <div className={"divideSummary"}>
          <p></p>
        </div>
      </section>
    </div>
  );

  const onClickTradeDeal = () => alert("준비중입니다");

  const renderData = ({
    title,
    description,
    tokenId,
    tokenRef,
    request: {
      author: { name },
    },
    caddress,
    summary,
    endpoint,
    category,
  }) => (
    <div className={"contents"}>
      <section className={"nftMain"}>
        <div className={"imgWrap"}>
          <img src={AWS_ENDPOINT.W280 + endpoint.replaceAll("origin/", "")} />
        </div>
        <div className={"infoWrap"}>
          <div className={"infoTop"}>
            <p className={"title"}>
              {description} <HiCheckCircle color={"#4285f4"} />
            </p>
            <span className={"host"}>{title}</span>
          </div>
          <div className={"infoBottom"}>
            <ul>
              <li>
                <span className={"label"}>보유자</span>
                <p className={"value"}>{name}</p>
              </li>
              <li>
                <span className={"label"}>토큰 ID</span>
                <p className={"value"}>{tokenId}</p>
              </li>
              <li>
                <span className={"label"}>컨트랙트 주소</span>
                <p className={"value"}>{caddress}</p>
              </li>
              <li>
                <span className={"label"}>토큰 표준</span>
                <p className={"value"}>{tokenRef}</p>
              </li>
              <li>
                <span className={"label"}>카테고리</span>
                <p className={"value"}>
                  {category != null &&
                    categories != null &&
                    category
                      .map(
                        (c, ci) =>
                          categories.data.find((f) => f.code === c).label
                      )
                      .join(" / ")}
                </p>
              </li>
            </ul>
            <button className={"reqButton"} onClick={onClickTradeDeal}>
              거래 제안하기
            </button>
          </div>
        </div>
      </section>
      <section className={"nftSummary"}>
        <div className={"divideSummary"}>
          <div dangerouslySetInnerHTML={{ __html: purify(summary) }}></div>
        </div>
      </section>
    </div>
  );

  return (
    <div className={"container sub"}>
      {/* subvisual */}
      <NFTSubVisual items={categories?.data} index={curFIndex} />
      {/* body */}
      <NftDetailWrap>
        {userInfoLoading ? renderInit() : renderData(userInfo.data)}
      </NftDetailWrap>
    </div>
  );
};

export default NFTDetail;

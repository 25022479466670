import { MSubVisual } from "../style/userNFTStyle";

export const PcSubvisual = () => (
  <div className={"container sub"}>
    <div className={"subVisual mypage"}>
      <span className={"sv_title"}>마이페이지</span>
    </div>
  </div>
);

export const MobileSubvisual = () => (
  <MSubVisual>
    <div className={"pageTitle"}>
      <span>마이 페이지</span>
    </div>
  </MSubVisual>
);

import { isMobile } from "react-device-detect";
import useUserState from "../../../components/hook/accountHook";
import { useQuery } from "react-query";
import { ClientReqGet2 } from "../../../utils/ClientAuth";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { MobileSubvisual, PcSubvisual } from "./components/userNFTCommon";
import { HistoryBody } from "./style/userNFTStyle";

const UserNFTHistory = () => {
  const { id, account, toggle } = useUserState();
  const navi = useNavigate();
  const { data, isLoading } = useQuery(
    ["userMyHistory", id, account, toggle],
    async () =>
      await ClientReqGet2(
        `/nftg/my`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false, suspense: false, retryOnMount: false }
  );

  const onClickItem = (_id) => navi(`/nftg/request/detail/${_id}`);

  const renderSkeleton = () => (
    <li key={`sdfsdf`}>
      <p className={"infoLoading skeleton"}>&nbsp;</p>
      <p className={"infoLoading skeleton"}>&nbsp;</p>
      <p className={"infoLoading skeleton"}>&nbsp;</p>
    </li>
  );

  const renderList = (_list) =>
    _list.length === 0 ? (
      <li className={"empty"}>
        <span>신청내역이 없습니다</span>
      </li>
    ) : (
      _list.map(({ _id, title, description, status, createdAt }, i) => (
        <li key={`nft_hs_${_id}`}>
          <p className={"cDate"}>
            {moment(createdAt).format("yyyy-MM-DD HH:mm")}
          </p>
          <div className={"cInfo"} onClick={() => onClickItem(_id)}>
            <div classNAme={"cTitles"}>
              <p className={"mainTitle"}>{description}</p>
              <p className={"subTitle"}>{title}</p>
            </div>
            <span
              className={`status ${
                status === 0 ? "ready" : status === 4 ? "success" : "reject"
              }`}
            >
              {status === 0 ? "검토중" : status === 4 ? "성공" : "반려"}
            </span>
          </div>
        </li>
      ))
    );

  return (
    <>
      {isMobile ? <MobileSubvisual /> : <PcSubvisual />}
      <HistoryBody>
        <div className={"contents"}>
          <div className={"cHeader"}>
            <p>NFT 발행 신청 히스토리</p>
          </div>
          <div className={"cBody"}>
            <ul>{isLoading ? renderSkeleton() : renderList(data.data)}</ul>
          </div>
        </div>
      </HistoryBody>
    </>
  );
};

export default UserNFTHistory;

import { useRecoilState } from "recoil";
import { accountSelect } from "../recoil/AtomConfig";
import { AuthPostAxios, ExpiredFilter, getRefreshToken } from "../utils/ReqApi";

export const useAuthReq = () => {
  const [account, setAccount] = useRecoilState(accountSelect);

  const authPostReq = async (url, data, token) => {
    const apiResult = await AuthPostAxios(url, data, token);
    const { data: postResult } = apiResult;
    if (
      postResult.statusCode == 401 &&
      postResult.message === "토큰이 만료되었습니다."
    ) {
      const refreshTokenResult = await getRefreshToken(account.refreshToken);

      setAccount((prev) => {
        const prevObj = { ...prev };
        prevObj.accessToken = refreshTokenResult.data.data.accessToken;
        return { ...prevObj };
      });

      return await ExpiredFilter(
        url,
        authPostReq,
        data,
        refreshTokenResult.data.data.accessToken
      );
    } else {
      return postResult;
    }
  };

  const authGetReq = async (url) => {};

  return {
    account: account,
    post: authPostReq,
    get: authGetReq,
  };
};

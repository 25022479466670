import styled from "styled-components";
import { SkeletonAnimate } from "../../../nftGallery/style/nftStyle";

export const MSubVisual = styled.div`
  position: relative;
  margin-top: 3.5em;
  height: 100%;

  .pageTitle {
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    > span {
      text-align: center;
      color: white;
      display: inline-block;
      border-bottom: 5px solid #e9360c;
      margin-bottom: 1em;
    }
  }
`;

export const HistoryBody = styled.div`
  width: 100%;
  background-color: #faf9f9;
  font-size: 24px;

  .contents {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    .cHeader {
      padding: 3em 0em;
      > p {
      }
    }
    .cBody {
      > ul {
        padding: 0.7em 1em;
        background-color: #ffffff;
        border-radius: 1em;
        > li {
          border-bottom: 1px solid rgba(0, 0, 0, 0.16);
          padding: 0.5em 0px;
          cursor: pointer;
          &.empty {
            text-align: center;
            font-size: 0.8em;
          }
          .cDate {
            font-size: 0.7em;
            color: rgb(153, 153, 153);
          }
          .cInfo {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .mainTitle {
              font-size: 0.85em;
              font-weight: 500;
            }
            .subTitle {
              font-size: 0.7em;
            }
          }
          .status {
            font-size: 0.6em;
            position: relative;

            &.ready::before,
            &.success::before,
            &.reject::before {
              content: " ";
              display: inline-block;
              width: 10px;
              height: 10px;
              position: relative;
              border-radius: 1em;
              margin-right: 10px;
            }
            &.ready::before {
              background-color: orange;
              color: orange;
            }
            &.success::before {
              background-color: green;
              color: green;
            }
            &.reject::before {
              background-color: red;
              color: red;
            }
          }
        }
      }

      .infoLoading {
        margin-bottom: 0.25em;
      }

      .skeleton {
        width: 100%;
        background: linear-gradient(
          90deg,
          #ebebeb 35%,
          #f9f9f9 50%,
          #ebebeb 65%
        );
        background-size: 300% auto;
        animation: ${SkeletonAnimate} 2s infinite linear;
      }
    }
  }

  .detailContents {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 2em 0;
    font-size: 24px;

    &.empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      font-size: 0.75em;
      text-align: center;
    }
    .dcHeader {
      width: 100%;
      border-bottom: 1px solid #ebebeb;
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
    }
    .dcWrap {
      display: flex;
      justify-content: flex-start;
      align-items: top;
      width: 100%;
      font-size: 0.75em;
      padding-bottom: 0.5em;

      > label {
        width: 20%;
        color: #4d4d4d;
      }
      > span,
      p,
      div {
        width: 80%;
      }
    }

    &.skeleton {
      .dcWrap {
        > span,
        p,
        div {
          background: linear-gradient(
            90deg,
            #ebebeb 35%,
            #f9f9f9 50%,
            #ebebeb 65%
          );
          background-size: 300% auto;
          animation: ${SkeletonAnimate} 2s infinite linear;
        }
      }
    }
    .dcButtonWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      > button {
        color: #ffffff;
        background-color: #2b303b;
        border: none;
        padding: 0.5em 1em;
        font-size: 1em;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 768px) {
    color: #000000;
    background-color: #ffffff;
    font-size: 18px;
    .contents {
      max-width: 100%;
      padding: 0 0em;

      .cHeader {
        padding: 1em 0em 0em 0em;
        border-bottom: 1px solid #ebebeb;
        > p {
          margin: 0 1em;
          padding-bottom: 0.5em;
        }
      }
      .cBody {
        padding: 0 1em;
        > ul {
          padding: 0;

          border-radius: 0;
          > li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.16);
            padding: 0.5em 0px;

            &.empty {
              font-size: 0.7em;
            }

            .cDate {
              font-size: 0.7em;
              color: rgb(153, 153, 153);
            }
            .cInfo {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .mainTitle {
                font-size: 0.85em;
                font-weight: 500;
              }
              .subTitle {
                font-size: 0.7em;
              }
            }
            .status {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.7em;
              &.ready::before,
              &.success::before,
              &.reject::before {
                width: 0.5em;
                height: 0.5em;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .detailContents {
      width: 100%;
      max-width: 100%;
      padding: 1em 1em;
      font-size: 18px;
      .dcHeader {
        width: 100%;
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
        font-weight: 500;
      }
      .dcWrap {
        display: block;
        width: 100%;
        font-size: 0.75em;
        padding-bottom: 1em;
        > label {
          width: 100%;
          color: #6c6c6c;
        }
        > span,
        p,
        div {
          display: block;
          width: 100%;
          padding: 0.5em 0;

          > img {
            width: 100%;
            max-width: 280px;
          }
        }
      }
      .dcButtonWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        > button {
          color: #ffffff;
          background-color: #2b303b;
          border: none;
          padding: 0.5em 1em;
          font-size: 1em;
          cursor: pointer;
        }
      }
    }
  }
`;

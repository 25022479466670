import { NFTFormSV } from "./components/nftCommon";
import { NftSuccessBodyWrap } from "./style/nftStyle";
import SuccessIcon from "../../assets/img/common/ico_success.png";
import { Link } from "react-router-dom";

const NftSubmitSuccessPage = () => {
  return (
    <div className={"container sub"}>
      <NFTFormSV
        label={"NFT 발행 신청 성공"}
        summary={"NFT 관련 서비스를 편리하게 이용하세요."}
      />
      <NftSuccessBodyWrap>
        <section>
          <img src={SuccessIcon} />
          <div className={"successBody"}>
            <p>NFT 발행 신청이 성공적으로 접수되었습니다</p>
            <p>신청해주셔서 감사합니다</p>
            <p>신청내역은 마이페이지에서 확인 할 수 있습니다</p>
          </div>
          <div className={"successBottom"}>
            <Link to="/user/nft">마이페이지 바로가기</Link>
          </div>
        </section>
      </NftSuccessBodyWrap>
    </div>
  );
};

export default NftSubmitSuccessPage;

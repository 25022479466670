import React from "react";
import { HiOutlineCube, HiOutlineDatabase } from "react-icons/hi";
import { FaBorderNone } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const MainMenu = ({ className, current, role, toggle }) => {
  const handleChangePage = (page) => {
    toggle(page);
  };
  const location = useLocation();

  // todo : temp style
  // todo : 추후 별도의 컴포넌트를 생성하여 관리할 것.
  const BcAfterStyle = styled(Link)`
    position: relative;
    /* ::after {
      position: absolute;
      top: -10px;
      left: 0;
      content: "NEW";
      color: yellow;
      font-size: 0.6em;
    } */
  `;

  return (
    <div className={className}>
      <ul>
        {/* temp 코인공모전 메뉴 */}
        <li
          className={current === "coin" ? "active" : null}
          style={{ position: "relative" }}
        >
          <BcAfterStyle
            to="/contest/coin"
            onClick={() => handleChangePage("coin")}
          >
            코인공모전
          </BcAfterStyle>
          <div style={{ position: "absolute", top: "-15px" }}>
            <HiOutlineCube color="yellow" size={"20px"} />
          </div>
        </li>
        <li className={current === "contest" ? "active" : null}>
          <Link
            to="/contest/contest"
            onClick={() => handleChangePage("contest")}
          >
            공모전
          </Link>
        </li>
        <li className={current === "activity" ? "active" : null}>
          <Link
            to="/contest/activity"
            onClick={() => handleChangePage("activity")}
          >
            대외활동
          </Link>
        </li>
        {/* <li>
              <Link to="/contents/emptyTime">
                <a>공강시간</a>
              </Link>
            </li> */}
        <li className={current === "dcontents" ? "active" : null}>
          <Link to="/dcontents" onClick={() => handleChangePage("dcontents")}>
            대티즌 콘텐츠
          </Link>
        </li>
        <li className={current === "event" ? "active" : null}>
          <Link to="/event" onClick={() => handleChangePage("event")}>
            이벤트
          </Link>
        </li>
        <li className={current === "nftg" ? "active" : null}>
          <Link to="/nftg" onClick={() => handleChangePage("nftg")}>
            NFT갤러리
          </Link>
        </li>
        {/* <li className={current === "event" ? "active" : null}>
          <Link to="/event">이벤트</Link>
        </li> */}
        <li className={current === "hostcenter" ? "active" : null}>
          <Link to="/hostcenter" onClick={() => handleChangePage("hostcenter")}>
            고객센터
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default MainMenu;

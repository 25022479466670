import React, { Suspense, useEffect } from "react";
import { HiLockClosed, HiPencil } from "react-icons/hi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import UserBasicInfo from "../../components/User/UserBasicInfo";
import UserBoardTop3 from "../../components/User/UserBoardTop3";
import {
  EmptyUserBasicInfo,
  EmptyUserScrapTop3,
} from "../../components/User/UserEmptyLayout";
import UserEventTop3 from "../../components/User/UserEventTop3";
import UserFreeTop3 from "../../components/User/UserFreeTop3";
import UserReplyTop3 from "../../components/User/UserReplyTop3";
import UserScrapTop3 from "../../components/User/UserScrapTop3";
import { accountSelect } from "../../recoil/AtomConfig";
import { useCookies } from "react-cookie";
import {
  CntBody,
  CntNumber,
  CntTitle,
} from "../../components/User/UserTemplate";
import { MyPoint } from "../../components/User/UserPoint";
import MyNFTCount from "../../components/User/UserNFTCount";

const ListWrap = styled.div`
  padding-top: 3em;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;
const ListTitleArea = styled.div`
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
const ListBody = styled.div`
  width: calc(100% / 2 - 2em);
`;
const ListFullBody = styled.div`
  width: calc(100%);
`;

const ListTitleText = styled.span`
  display: inline-block;
  line-height: 1.8em;
  font-size: 1.5em;
  font-weight: bold;
  color: #282c36;
`;
const ListMoreText = styled.span`
  float: right;
  cursor: pointer;
`;

const UserIndex = () => {
  const { id } = useParams();
  const navi = useNavigate();
  const [account, setAccount] = useRecoilState(accountSelect);
  const [cookies] = useCookies(["rtk"]);
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };
  const handleLogout = () => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.id = undefined;
      temp.accessToken = undefined;
      temp.role = undefined;
      temp.refreshToken = undefined;
      return { ...temp };
    });
    navi("/signin");
  };
  const handleEdit = () => {
    navi(`/user/changeinfo/${id}`);
  };
  useEffect(() => {
    console.log(cookies.rtk);
  }, [cookies]);

  return (
    <>
      <div className={"container sub"}>
        <div className={`subVisual mypage`}>
          <span className={"sv_title"}>마이페이지</span>
        </div>
      </div>
      <div className={`sub_container`}>
        <div className={`contents mypage`}>
          <h2
            className={"sub_title"}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "100%" }}>내 정보</span>

            <div>
              <div onClick={handleLogout}>
                <span
                  style={{
                    height: "17px",
                    float: "right",
                    cursor: "pointer",
                    fontSize: "17px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>로그아웃</span>

                  <HiLockClosed color="#a0a0a0" size={20} />
                </span>
              </div>
              <div>
                <span
                  style={{
                    float: "right",
                    cursor: "pointer",
                    fontSize: "17px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={handleEdit}
                >
                  <span>수정</span>

                  <HiPencil color="#a0a0a0" size={20} />
                </span>
              </div>
            </div>
          </h2>

          <div className={`myInfo_area`}>
            <Suspense fallback={<EmptyUserBasicInfo />}>
              <UserBasicInfo id={id} account={account} toggle={toggle} />
            </Suspense>

            <div style={{ marginTop: "3em" }} role="point wrap">
              <h2
                style={{
                  color: "#282c36",
                  fontSize: "1.5em",
                  lineHeight: "1.8em",
                }}
              >
                내 지갑
              </h2>
              <div
                style={{
                  borderTop: "1px solid black",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <CntBody>
                  <CntTitle>내 포인트</CntTitle>
                  <Suspense fallback={<CntNumber>0</CntNumber>}>
                    <MyPoint id={id} account={account} toggle={toggle} />
                  </Suspense>
                </CntBody>
                <CntBody>
                  <CntNumber>
                    <button
                      type={"button"}
                      style={{
                        border: "none",
                        fontSize: "0.7em",
                        padding: ".5em 1em",
                        borderRadius: "1em",
                        color: "white",
                        background:
                          "linear-gradient(130deg, #12c2e9, #c471ed, #f64f59)",
                        boxShadow: "0px 0px 10px #00000029",
                        cursor: "pointer",
                      }}
                    >
                      포인트 전환하기
                    </button>
                  </CntNumber>
                </CntBody>
                <CntBody>
                  <CntTitle>NFT 발행 신청</CntTitle>
                  <Suspense fallback={<CntNumber>-</CntNumber>}>
                    <MyNFTCount id={id} account={account} toggle={toggle} />
                  </Suspense>
                </CntBody>
              </div>
            </div>

            <ListWrap role="scrap list">
              <ListBody role="scrap header">
                <ListTitleArea>
                  <ListTitleText role="title">스크랩</ListTitleText>
                  <ListMoreText
                    style={{ cursor: "pointer" }}
                    onClick={() => navi(`/user/myscrap`)}
                  >
                    ...
                  </ListMoreText>
                </ListTitleArea>
                <Suspense fallback={<EmptyUserScrapTop3 />}>
                  <UserScrapTop3 id={id} account={account} toggle={toggle} />
                </Suspense>
              </ListBody>
              <ListBody role="scrap header">
                <ListTitleArea>
                  <ListTitleText role="title">이벤트</ListTitleText>
                  <Link to={`/user/myevent/${id}`}>
                    <ListMoreText>...</ListMoreText>
                  </Link>
                </ListTitleArea>
                <Suspense fallback={<div>event~~</div>}>
                  <UserEventTop3 id={id} account={account} toggle={toggle} />
                </Suspense>
              </ListBody>
            </ListWrap>

            {/* <ListWrap role="scrap list">
              <ListBody role="scrap header">
                <ListTitleArea>
                  <ListTitleText role="title">내가 쓴 게시물</ListTitleText>
                  <ListMoreText onClick={() => navi(`/user/mycontents`)}>
                    ...
                  </ListMoreText>
                </ListTitleArea>
                <Suspense fallback={<div>board~~~</div>}>
                  <UserBoardTop3 id={id} account={account} toggle={toggle} />
                </Suspense>
              </ListBody>
              <ListBody role="scrap header">
                <ListTitleArea>
                  <ListTitleText role="title">
                    내 게시물에 달린 댓글
                  </ListTitleText>
                  
                </ListTitleArea>
                <UserReplyTop3 id={id} account={account} toggle={toggle} />
              </ListBody>
            </ListWrap> */}
            <ListWrap>
              <ListFullBody>
                <ListTitleArea>
                  <ListTitleText role="title">무료등록</ListTitleText>
                  <ListMoreText onClick={() => navi(`/user/myFreeContest`)}>
                    ...
                  </ListMoreText>
                </ListTitleArea>
                <UserFreeTop3 id={id} account={account} toggle={toggle} />
              </ListFullBody>
            </ListWrap>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserIndex;

import { useNavigate, useParams } from "react-router-dom";
import useUserState from "../../../components/hook/accountHook";
import DOMPurify from "dompurify";
import { useQuery } from "react-query";
import { ClientReqGet2 } from "../../../utils/ClientAuth";
import { isMobile } from "react-device-detect";
import { MobileSubvisual, PcSubvisual } from "./components/userNFTCommon";
import { HistoryBody } from "./style/userNFTStyle";
import moment from "moment";
import { AWS_ENDPOINT } from "../../../utils/commonVariable";

const NFTRequestDetail = () => {
  const { id: _reqId } = useParams();
  const { id, account, toggle } = useUserState();
  const navi = useNavigate();
  const protect = DOMPurify.sanitize;
  const { data, isLoading } = useQuery(
    ["userMyReqDetail", id, account, toggle],
    async () =>
      await ClientReqGet2(
        `/nftg/my/detail/${_reqId}`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    { retry: false, suspense: false, retryOnMount: false }
  );

  const onClickBack = () => navi("/user/nft");

  const renderSkeleton = () => (
    <div className={"detailContents skeleton"}>
      <div className={"dcHeader"}>
        <p>&nbsp;</p>
      </div>
      <div className={"dcWrap"}>
        <label>접수 상태</label>
        <span>&nbsp;</span>
      </div>
      <div className={"dcWrap"}>
        <label>접수 일시</label>
        <span>&nbsp;</span>
      </div>
      <div className={"dcWrap"}>
        <label>공모전 명</label>
        <span>&nbsp;</span>
      </div>
      <div className={"dcWrap"}>
        <label>작품 제목</label>
        <span>&nbsp;</span>
      </div>
      <div className={"dcWrap"}>
        <label>작품 소개</label>
        <div>&nbsp;</div>
      </div>
      <div className={"dcWrap"}>
        <label>파일</label>
        <div>&nbsp;</div>
      </div>
      <div className={"dcWrap"}>
        <label>지갑 주소</label>
        <span>&nbsp;</span>
      </div>
      <div className={"dcWrap"}>
        <label>추가 요청사항</label>
        <span>&nbsp;</span>
      </div>
      <div className={"dcButtonWrap"}>&nbsp;</div>
    </div>
  );

  const renderItem = ({
    title,
    description,
    summary,
    endpoint,
    caddress,
    etcs,
    status,
    createdAt,
  }) => (
    <div className={"detailContents"}>
      <div className={"dcHeader"}>
        <p>NFT 발행 신청 접수내역</p>
      </div>
      <div className={"dcWrap"}>
        <label>접수 상태</label>
        <span>
          {status === 0 ? "검토중" : status === 4 ? "처리완료" : "반려"}
        </span>
      </div>
      <div className={"dcWrap"}>
        <label>접수 일시</label>
        <span>{moment(createdAt).format("yyyy년 MM월 DD일 HH시 mm분")}</span>
      </div>
      <div className={"dcWrap"}>
        <label>공모전 명</label>
        <span>{title}</span>
      </div>
      <div className={"dcWrap"}>
        <label>작품 제목</label>
        <span>{description}</span>
      </div>
      <div className={"dcWrap"}>
        <label>작품 소개</label>
        <div dangerouslySetInnerHTML={{ __html: protect(summary) }}></div>
      </div>
      <div className={"dcWrap"}>
        <label>파일</label>
        <div>
          <img src={AWS_ENDPOINT.W280 + endpoint.replace("origin/", "")} />
        </div>
      </div>
      <div className={"dcWrap"}>
        <label>지갑 주소</label>
        <span>{caddress}</span>
      </div>
      <div className={"dcWrap"}>
        <label>추가 요청사항</label>
        <span>{etcs}</span>
      </div>
      <div className={"dcButtonWrap"}>
        <button type={"button"} onClick={onClickBack}>
          목록으로
        </button>
      </div>
    </div>
  );

  const rednerError = () => (
    <div className={"detailContents empty"}>
      <p>
        요청하신 내용을 처리할 수 없습니다. 잠시 후 다시 시도해주시기 바랍니다
      </p>
    </div>
  );

  return (
    <>
      {isMobile ? <MobileSubvisual /> : <PcSubvisual />}
      <HistoryBody>
        {isLoading
          ? renderSkeleton()
          : data.data != null
          ? renderItem({ ...data.data })
          : rednerError()}
      </HistoryBody>
    </>
  );
};

export default NFTRequestDetail;

import React, { Suspense, useMemo, useState } from "react";
import { useQuery } from "react-query";
import styled, { css } from "styled-components";
import { LoginPostApi, NormalReqApi } from "../../utils/ReqApi";
import DefaultImg from "../../assets/img/common/img_posterDefault_350.jpg";
import Logo from "../../assets/img/common/logo_w.png";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../recoil/AtomConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

export default function LoginTest() {
  const [account, setAccount] = useState({
    id: undefined,
    pwd: undefined,
  });
  const [state, setState] = useRecoilState(accountSelect);
  const route = useNavigate();

  const onSignUpClick = () => {
    route("/signup");
  };

  const onLoginClick = (e) => {
    e.preventDefault();

    handleReqLogin();
  };
  useEffect(() => {
    // setState((prev) => )
    setState((prev) => {
      const temp = { ...prev };
      temp.id = undefined;
      temp.accessToken = null;
      temp.role = "";
      temp.refreshToken = null;
      return { ...temp };
    });
    return () => {};
  }, []);

  const handleReqLogin = async () => {
    const reqBody = {
      email: account.id,
      password: account.pwd,
    };

    const res = await LoginPostApi("/auth/login", reqBody);
    if (res.status === 200) {
      const { data } = res;
      setState((prev) => {
        const prevObject = { ...prev };
        prevObject.id = data.data.id;
        prevObject.accessToken = data.data.accessToken;
        prevObject.role = data.data.userType === 2 ? "COMPANY" : "NORMAL";
        prevObject.refreshToken = data.data.refreshToken;
        return { ...prevObject };
      });
      route("/");
    } else if (res.status === 204) {
      toast.error(
        <span style={{ fontSize: "0.65em" }}>
          아이디 / 비밀번호를 확인해주세요!
        </span>,
        {
          theme: "colored",
          closeButton: false,
        }
      );
    }
  };

  let tempItem = useMemo(() => {
    return <LoginBannerItems />;
  }, []);

  return (
    <LoginBox>
      <LoginWrap>
        <LoginFormBox>
          <form onSubmit={onLoginClick}>
            <LoginForm>
              <label>아이디</label>
              <input
                type="text"
                placeholder="이메일 주소"
                onChange={(e) => setAccount({ ...account, id: e.target.value })}
              />
            </LoginForm>
            <LoginForm>
              <label>비밀번호</label>
              <input
                type="password"
                placeholder="비밀번호"
                onChange={(e) =>
                  setAccount({ ...account, pwd: e.target.value })
                }
              />
            </LoginForm>
            <LoginButtonBox>
              <LoginButton type="button" onClick={onLoginClick}>
                로그인
              </LoginButton>
            </LoginButtonBox>
            <LoginButtonBox>
              <LoginButton type="button" onClick={onSignUpClick}>
                회원가입
              </LoginButton>
            </LoginButtonBox>
            <LoginLink>
              <Link to="/findid">아이디 찾기</Link>
            </LoginLink>
            <LoginLink>
              <Link to="/findpwd">비밀번호 찾기</Link>
            </LoginLink>
          </form>
        </LoginFormBox>
        <LoginBannerBox>
          <Suspense
            fallback={
              <LoginBanner empty={true}>
                <img src={Logo} />
              </LoginBanner>
            }
          >
            {tempItem}
            {/* <LoginBannerItems /> */}
          </Suspense>
        </LoginBannerBox>
      </LoginWrap>
      <ToastContainer
        position="bottom-center"
        theme="light"
        closeOnClick
        hideProgressBar={true}
      />
    </LoginBox>
  );
}

const LoginBannerItems = () => {
  const route = useNavigate();
  const { data } = useQuery(
    ["loginBanner"],
    async () => await NormalReqApi("get", "/banner/login"),
    {
      retry: true,
      suspense: true,
    }
  );

  const onDetailClick = (id, type) => {
    route(`/contest/view/${type}/${id}`);
  };

  console.log(data);

  const slideSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    Infinity: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return data.statusCode === 200 && data.data.length > 0 ? (
    <Slider {...slideSettings}>
      {data.data
        .sort(() => Math.random() - 0.5)
        .map((item, index) => (
          <LoginBanner
            key={`lb_${index}`}
            onClick={() => onDetailClick(item.contest._id, item.contest.type)}
          >
            <img src={item.thumbnail.path} />
          </LoginBanner>
        ))}
    </Slider>
  ) : (
    <LoginBanner empty={true}>
      <img src={Logo} />
    </LoginBanner>
  );
};

const LoginBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 900px;
  height: 100vh;
  min-height: 643px;
  margin: 0 auto;
  /* margin-top: 6em; */
`;
const LoginWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  /* box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 10px 1px; */
`;

const LoginFormBox = styled.div`
  padding: 1.25em 1em 1em;
  width: 500px;
  /* height: 500px; */
  background-color: transparent;
`;
const LoginForm = styled.div`
  display: block;
  margin-bottom: 1.5em;

  > label {
    display: block;
    color: white;
    font-size: 0.75em;
    padding-bottom: 0.3em;
  }
  > input {
    display: block;
    margin-bottom: 0.5em;
    padding: 0.5em 1em;
    width: 100%;
    font-size: 0.75em;
    // case1
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border: none;
    border-radius: 5px;

    /* case2  */
    /* background-color: transparent;
    color: white;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4); */

    &:focus {
      outline: none;
    }
  }
`;
const LoginButtonBox = styled.div``;
const LoginButton = styled.button`
  width: 100%;
  margin-bottom: 1em;
  padding: 0.5em 1em;
  font-size: 0.75em;
  color: white;
  background-color: transparent;
  border: none;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 0px 10px 1px;
  cursor: pointer;
`;
const LoginLink = styled.p`
  margin-bottom: 1em;
  color: white;
  font-size: 0.55em;
  text-align: right;
  > a {
    color: white;
  }
`;
const LoginBannerBox = styled.div`
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* width: 100%; */
  width: 400px;
  height: 300px;
  padding: 1.25em 1em 1em;
  margin-top: 27px;

  .slick-slider {
    width: 400px;
    outline: none;

    .slick-slide {
      outline: none !important;

      div {
        outline: none;
      }
    }
    /* width: 300px; */
  }
`;
const LoginBanner = styled.div`
  width: 400px;
  height: 300px;
  /* background: linear-gradient(137deg, #fff6b7, #f77c99, #6578f2, #c2ffd8); */

  ${(props) =>
    props.empty
      ? css`
          /* background: linear-gradient(137deg, #fff6b7, #f77c99, #6578f2, #c2ffd8); */
          display: flex;
          justify-content: center;
          align-items: center;
        `
      : css`
          cursor: pointer;
        `}
`;

import styled, { keyframes } from "styled-components";

export const LoadingSpinnerAnimate = keyframes`
0%{
  transform: rotate(0deg);
}
100%{
  transform:rotate(360deg);
}
`;

export const SkeletonAnimate = keyframes`
0%{
  background-position: 100% 0%;
}
100%{
  background-position: 0% 0%;
}
`;

const _formFontSize = "18px";
const _formFontSizeMobile = "15px";

// thumbnail & filter subvisual
export const NFTSubVisualDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding-top: 3.5em;
  padding-bottom: 1em;
  width: 100%;
  height: 19.5em;
  box-shadow: 0px 0px 10px 1px;
  gap: 20px 0;

  .tempImg {
    width: 100%;
    max-width: 1200px;
    /* height: 300px; */
    background-color: #000000;
    &.skeleton {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > img {
      width: 100%;
      max-width: 1200px;
    }
  }
  .fItems {
    display: flex;
    gap: 0 20px;
    > li {
      color: #ffffff;
      font-size: 0.75em;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px) {
    height: 13.5em;
    .tempImg {
      max-width: 512px;
      /* height: 150px; */
    }
    .fItems {
      flex-wrap: nowrap;
      overflow-x: auto;
      width: 100%;
      gap: 0;
      > li {
        font-size: 0.5em;
        padding: 0 1em;
      }
    }
  }
  @media screen and (max-width: 425px) {
    height: 10.5em;
    .tempImg {
      max-width: 100%;
    }
  }
`;

// form subvisual
export const NFTFormSubVisual = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3.5em;
  padding-bottom: 1em;
  width: 100%;
  height: 14.5em;
  box-shadow: 0px 0px 10px 1px;
  gap: 10px 0;

  p {
    margin-bottom: 0;
    &.label {
      font-size: 1.5em;
      font-weight: 600;
      padding: 0.3em 1.2em;
      border-radius: 1em;
      background-color: #ffffff;
    }
    &.summary {
      color: #ffffff;
      font-size: 0.75em;
    }
  }

  @media screen and (max-width: 768px) {
    height: 10.5em;
    p {
      &.label {
        font-size: 1em;
      }
      &.summary {
        font-size: 0.6em;
      }
    }
  }
  @media screen and(max-width:425px) {
    p {
      &.label {
        font-size: 0.75em;
      }
      &.summary {
        font-size: 0.6em;
      }
    }
  }
`;

export const NFTBodyWrap = styled.div`
  background-color: #ffffff;
  width: 100%;
  padding: 1.5em 0;

  section {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    &.bodyTop {
      text-align: end;
      margin-bottom: 1em;

      > a {
        padding: 0.5em 1em;
        width: auto;
        color: #fff;
        border: none;
        cursor: pointer;
        font-size: 0.8em;
        background-color: #263050;
        border-radius: 5em;
        box-shadow: #b9b9b9 0px 5px 10px 1px;
      }
    }

    &.nftBody {
      .nftItems {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        grid-gap: 2em 1em;

        .nftItem {
          cursor: pointer;
        }

        .nftb_img {
          width: 100%;

          text-align: center;
          background-color: #263050;

          &.skeleton {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          > img {
            width: 100%;
            display: block;
          }
        }
        .nftb_text {
          font-size: 0.75em;
          &.title {
            font-weight: 600;
            margin-top: 0.25em;
            margin-bottom: 0.45em;
          }
          &.author {
            font-size: 0.85rem;
            color: #9b9b9b;
          }
          &.summary {
            font-size: 0.85rem;
            color: #9b9b9b;

            &::before {
              content: "by";
              color: #f9b33f;
              margin-right: 0.5em;
            }
          }

          /* .skeleton {
            width: 100%;
            background: linear-gradient(90deg, #ebebeb, #ffffff, #ebebeb);
          } */
        }
      }
    }

    .skeleton {
      width: 100%;
      background: linear-gradient(90deg, #ebebeb 35%, #f9f9f9 50%, #ebebeb 65%);
      background-size: 300% auto;
      animation: ${SkeletonAnimate} 2s infinite linear;
      &::before {
        content: "" !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    section {
      max-width: 100%;
      padding: 0 0.5em;
      margin: 0;

      &.bodyTop {
        > a {
          padding: 0.5em 1em;
          font-size: 0.6em;
        }
      }
    }
  }
`;

export const NFTFormWrap = styled.div`
  position: relative;
  padding: 2em 0;
  background-color: #faf9f9;
  font-size: ${_formFontSize};

  .formBody {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    .formLoading {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: #555555e3;
      z-index: 1111;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    section {
      margin-bottom: 2em;

      > label {
        width: 100%;
        display: block;
        margin-bottom: 0.5em;
        border-bottom: 2px solid #9b9b9b;
        font-size: 1.2em;
        font-weight: 600;
      }

      .formInput {
        margin-bottom: 1em;

        input {
          padding: 0.5em 0.75em;
          color: #202020;
          outline: none;
          font-size: 1em;

          &:focus {
            border: 1px solid #ebebeb;
          }
          &:active {
            border: 1px solid #ebebeb;
          }
          &::placeholder {
            font-size: 0.75em;
          }
        }

        .inputWrap {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          label {
            width: 20%;
          }
          input {
            width: 80%;
            border: none;
          }

          .inputFile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 1em;
            width: 80%;
            background-color: #ffffff;
            input[type="file"] {
              display: none;
            }
            span {
              font-size: 0.9em;
            }

            button {
              font-size: 1em;
              border: none;
              padding: 0.75em 1em;
            }
          }

          .inputCheck {
            width: 80%;
            display: inline-block;
            label {
              width: auto;
              margin-right: 1em;
            }
            input {
              width: auto;
            }
          }

          .checkList {
            width: 80%;
            display: flex;
            flex-wrap: wrap;
            border: 1px solid #ebebeb;
            background-color: #ffffff;
            padding: 0.75em 1em;
            .checkItem {
              display: inline-block;
              margin-right: 1em;
              font-size: 0.8em;
              > label {
                width: auto;
                padding: 0.25em 0.5em;
                margin-right: 0.25em;
              }
              > input {
                width: auto;
                display: none;

                &:checked + label {
                  color: #ffffff;
                  background-color: #2b303b;
                  border-radius: 4px;
                }
              }
            }
          }

          .inputTextArea {
            width: 80%;
            .inputEditor {
              background-color: #ffffff;

              .ql-container {
                border: none;
                font-size: 1em;
              }
            }
          }
        }
        p.errorMsg {
          color: red;
          text-align: end;
          font-size: 0.75em;
        }
      }

      &.submitArea {
        display: flex;
        justify-content: center;
        align-items: center;
        .nftSubmit {
          color: #ffffff;
          background-color: #2b303b;
          border: none;
          padding: 0.5em 1em;
          font-size: 1.5em;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    font-size: ${_formFontSizeMobile};
    padding: 2em 1em;

    .formBody {
      max-width: 100%;
      margin: 0;

      section {
        margin-bottom: 2em;
        > label {
          font-size: 1.3em;
          font-weight: 600;
          border-bottom: 2px solid #2b303b;
          margin-bottom: 1em;
        }

        .formInput {
          margin-bottom: 1em;

          input {
            padding: 0.75em 1em;
            width: 100%;
            border: none;
            margin-bottom: 0;
            outline: none;
            font-size: 1em;
            border-radius: 0.25em;
          }

          .inputWrap {
            flex-direction: column;
            label {
              font-size: 0.85em;
              color: #acacac;
              width: 100%;
              margin-bottom: 0.25em;
            }
            input {
              width: 100%;
            }

            .inputFile {
              width: 100%;
            }
            .inputCheck {
              width: 100%;
            }
            .checkList {
              width: 100%;
              .checkItem {
                font-size: 1em;
              }
            }
            .inputTextArea {
              width: 100%;

              .ql-container {
                min-height: 200px;
              }
            }
          }
        }
      }
    }
  }
`;

export const NftSuccessBodyWrap = styled.div`
  background-color: #ffffff;
  width: 100%;
  padding: 1.5em 2em;
  display: flex;
  justify-content: center;
  font-size: 18px;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 768px;

    text-align: center;

    > img {
      display: block;
      margin-bottom: 1em;
    }

    .successBody p {
      margin-bottom: 1em;
    }
    .successBottom {
      margin: 1em 0;
      > a {
        padding: 0.5em 0.75em;
        color: #ffffff;
        background-color: #2b303b;
      }
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 13px;
    padding: 3em 2em;
    section {
      > img {
        width: 140px;
        margin-bottom: 3em;
      }
    }
  }
`;

export const NftDetailWrap = styled.div`
  position: relative;
  width: 100%;
  padding: 3em 6em;
  background-color: #ffffff;
  .contents {
    display: block;
    max-width: 1200px;
    section {
      &.nftMain {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        .imgWrap {
          margin-right: 2em;
          > img {
            margin-right: 2em;
            width: 14.58333333333333em;
          }
        }
        .infoWrap {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: calc(100% - 16.58333333333333em);
          .infoTop {
            position: relative;
            padding: 1.25em 0;
            width: 100%;
            border-top: 1px solid #7f7f7f;
            border-bottom: 1px solid #7f7f7f;
            > .title {
              display: flex;
              justify-content: flex-start;
              align-items: end;
              line-height: 1.1;
            }
            > .host {
              font-size: 0.75em;
              &::before {
                content: "by";
                color: #f9b33f;
                margin-right: 0.5em;
              }
            }
          }
          .infoBottom {
            width: 100%;
            ul {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: stretch;
              li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0.5em 0;
                width: calc((100%) / 2);
                border-bottom: 1px solid #909297;

                .label {
                  width: 5.5em;
                  font-size: 0.75em;
                  color: #4285f4;
                }
                .value {
                  width: calc(100% - 6.5em);
                  font-size: 0.67em;
                  line-height: 1.5em;
                  font-weight: 600;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  white-space: normal;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  max-height: 3em;
                  word-break: break-all;
                }
              }
            }
            .reqButton {
              font-size: 0.75em;
              border: none;
              background-color: black;
              color: #ffffff;
              padding: 0.5em 0.75em;
              cursor: pointer;
            }
          }
        }
      }
      &.nftSummary {
        padding-top: 3.5em;
        margin-top: 1em;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        padding: 1.75em 0;
        .divideSummary {
          &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 100%;
            top: 0;
            left: 14.58333333333333em;
            background-color: rgba(15, 15, 15, 0.2);
          }
          padding: 1.3em 0 0 16em;
          p {
            width: calc(100%);
            font-size: 0.75em;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    background-color: #2b303b;
    padding: 1em 0;
    .contents {
      max-width: 100%;
      section {
        display: flex;
        flex-direction: column;
        align-items: start;
        &.nftMain {
          .imgWrap {
            margin-right: 0;
            width: 100%;
            > img {
              margin: unset;
              width: 100%;
              display: block;
            }
          }
          .infoWrap {
            padding: 0 1em;
            font-size: 18px;
            width: 100%;
            color: #ffffff;
            .infoTop {
              span {
                display: block;
                padding: 0;

                &.host {
                  font-size: 0.75em;
                  color: #dbdbdb;
                  &::before {
                    content: "by";
                    color: #f9b33f;
                    margin-right: 0.5em;
                  }
                }
              }
            }
            .reqButton {
              margin-top: 1em;
              width: 100%;
            }
          }
        }

        &.nftSummary {
          padding: 0;
          color: #ebebeb;
          font-size: 18px;
          .divideSummary {
            &::before {
              content: unset;
            }
            margin-top: 1em;
            padding: 0.25em 1em;
          }
        }
      }
    }
  }
`;

export const NftSpinner = styled.div`
  width: 2em;
  height: 2em;
  border: 5px solid #f3f3f3;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: ${LoadingSpinnerAnimate} 1.5s infinite linear;
`;

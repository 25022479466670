import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ClientReqGet2 } from "../../utils/ClientAuth";
import { CntClickableNumber } from "./UserTemplate";
import { useEffect } from "react";

const MyNFTCount = ({ id, account, toggle }) => {
  const navi = useNavigate();
  const { data } = useQuery(
    ["userNFTCnt", id, account, toggle],
    async () =>
      await ClientReqGet2(
        `/nftg/my/cnt`,
        account.accessToken,
        account.refreshToken,
        toggle
      ),
    {
      retry: false,
      suspense: true,
      retryOnMount: true,
    }
  );

  const onClick = () => navi("/user/nft");

  if (!data.data) {
    return <CntClickableNumber onClick={onClick}>-</CntClickableNumber>;
  }

  return (
    <CntClickableNumber onClick={onClick}>{data?.data.cnt}</CntClickableNumber>
  );
};

export default MyNFTCount;

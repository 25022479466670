import { NFTFormSubVisual, NFTSubVisualDiv } from "../style/nftStyle";
import LogoW from "../../../assets/img/common/logo_w.png";
import Banner from "../../../assets/img/common/main_bannerv2.png";

export const NFTFormSV = ({ label, summary }) => {
  return (
    <NFTFormSubVisual>
      <p className={"label"}>{label}</p>
      <p className={"summary"}>{summary}</p>
    </NFTFormSubVisual>
  );
};

export const NFTSubVisual = ({ items, curCg, changeCg }) => {
  return (
    <NFTSubVisualDiv>
      <div className={"tempImg skeleton"}>
        <img src={Banner} />
      </div>
      <ul className={"fItems"}>
        {items != null &&
          items.map((e, i) => (
            <li
              key={`fi_${e._id}`}
              onClick={() => (changeCg ? changeCg(e.code) : {})}
            >
              {e.label}
            </li>
          ))}
      </ul>
    </NFTSubVisualDiv>
  );
};

import { useForm } from "react-hook-form";
import {
  NFTFormSubVisual,
  NFTFormWrap,
  NftSpinner,
  NFTSubVisualDiv,
} from "./style/nftStyle";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useRef, useState } from "react";
import Editor from "../../components/Common/Editor";
import { NFTFormSV } from "./components/nftCommon";
import { useNavigate } from "react-router-dom";
import { useAuthReq } from "../../hook/userReq";
import { s3SingleFileUpload } from "../../utils/FileController";
import { useQuery } from "react-query";
import { ClientOptionGet } from "../../utils/ClientAuth";

/* main form page */
const NftForm = () => {
  return (
    <div className={"container sub"}>
      {/* common NFT subvisual */}
      <NFTFormSV
        label={"NFT 발행 신청"}
        summary={"NFT 관련 서비스를 편리하게 이용하세요."}
      />
      {/* form's logic belowing code */}
      <NFTFormBody />
    </div>
  );
};

// form body
const NFTFormBody = () => {
  const { data: categories, isLoading } = useQuery(
    ["nftCategory"],
    async () => await ClientOptionGet(`/nftg/category`),
    { retry: false, retryOnMount: false, suspense: false }
  );
  // phone reg condition
  const phoneRegExp = /^[0-9]{8,}$/;

  const navi = useNavigate();

  // input validation condition
  const inputSchema = yup.object({
    userName: yup.string().required("이름을 입력해주세요"),
    userDesc: yup.string().required("본인 소개를 입력해주세요"),
    userPhone: yup
      .string()
      .required("휴대전화 번호를 입력해주세요")
      .matches(phoneRegExp, "'-'을 제외한 숫자만 입력해주세요"),
    userEmail: yup.string().required("이메일을 입력해주세요"),
    file: yup
      .mixed()
      .test({ message: "파일을 선택해주세요", test: (value) => value != null }),
    title: yup.string().required("공모전 명을 입력해주세요"),
    description: yup.string().required("작품 제목을 입력해주세요"),
    summary: yup.string().required("작품 소개를 입력해주세요"),
    category: yup
      .mixed()
      .test({
        message: "카테고리를 선택해주세요",
        test: (array) => array != null,
      })
      .test({
        message: "카테고리를 선택해주세요",
        test: (array) => array.length > 0,
      }),
    caddress: yup.string().required("지갑 주소를 입력해주세요"),
    agree: yup.bool().test({
      message: "약관에 동의해주시기 바랍니다",
      test: (agree) => agree,
    }),
  });

  // useForm
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(inputSchema) });

  const [submitLoading, setSubmitLoading] = useState(false);

  const { account, post, get } = useAuthReq();

  // onSubmit
  const onSubmit = async (data) => {
    let resultFlag = false;
    if (account.accessToken == null) {
      alert("로그인 후 이용해주시기 바랍니다.");
      return false;
    }
    // 시작 (loading spinner 호출)
    setSubmitLoading(true);

    console.log("onSubmit data : " + data);
    console.log(data);

    // todo : api 호출 및 응답 처리

    const fileUpload = await s3SingleFileUpload(data.file, "thumb");
    if (fileUpload == null) {
      alert(
        "파일을 업로드하는 도중 오류가 발생했습니다. 잠시 후 다시 시도해주시기 바랍니다"
      );
      return false;
    }
    data.endpoint = fileUpload;

    const resultData = await post(`/nftg/form`, data, account.accessToken);
    if (resultData.hasOwnProperty("flag")) {
      if (!resultData.flag) {
        if (resultData.data === -1) {
          alert("잠시 후 다시 시도해주시기 바랍니다");
        } else {
          alert("잠시 후 다시 시도해주시기 바랍니다.");
        }
      }
      resultFlag = resultData.flag;
    } else {
      alert("접수내용을 확인해주시기 바랍니다.");
      resultFlag = false;
    }
    setSubmitLoading(false);
    if (resultFlag) {
      navi("/nftg/form/success", { replace: true });
    }
  };

  return (
    <NFTFormWrap>
      <div className={"formBody"}>
        {submitLoading && (
          <div className={"formLoading"}>
            <NftSpinner />
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <section>
            <label>신청자 정보</label>
            {/* input element & error  */}
            <TextInput
              label={"이름"}
              name={"userName"}
              register={register}
              error={errors?.userName}
            />
            <TextInput
              label={"본인 소개"}
              name={"userDesc"}
              register={register}
              error={errors?.userDesc}
            />
            <TextInput
              label={"연락처"}
              name={"userPhone"}
              register={register}
              error={errors?.userPhone}
            />
            <TextInput
              label={"이메일 주소"}
              name={"userEmail"}
              register={register}
              error={errors?.userEmail}
            />
          </section>
          <section>
            <label>NFT 출품작 정보</label>
            {/* input element & error */}
            <TextInput
              label={"공모전명"}
              name={"title"}
              register={register}
              error={errors?.title}
            />
            <TextInput
              label={"작품 제목"}
              name={"description"}
              register={register}
              error={errors?.description}
            />
            {/* 작품소개(에디터 사용 필요) */}
            <EditorInput
              label={"작품 소개"}
              name={"summary"}
              watch={watch}
              setValue={setValue}
              error={errors?.summary}
            />
            {/* 파일 업로드 */}
            <FileInput
              label={"파일 업로드"}
              name={"file"}
              setValue={setValue}
              error={errors?.file}
            />
            {/* 작품 카테고리 */}
            <CheckBoxListInput
              label={"작품 카테고리"}
              name={"category"}
              register={register}
              error={errors?.category}
              items={categories?.data}
            />
            <TextInput
              label={"지갑 주소"}
              name={"caddress"}
              placeholder={"NFT를 받을 수 있는 개인 지갑 주소"}
              register={register}
              error={errors?.caddress}
            />

            <TextInput
              label={"추가 요청 사항"}
              name={"etcs"}
              register={register}
              error={errors?.etcs}
            />
            <CheckBoxInput
              label={"동의 사항"}
              checkLabel={
                "개인정보 처리 방침 및 NFT 발행 조건에 대한 동의 확인"
              }
              name={"agree"}
              register={register}
              error={errors?.agree}
            />
          </section>
          <section className={"submitArea"}>
            <button className={"nftSubmit"}>신청하기</button>
          </section>
        </form>
      </div>
    </NFTFormWrap>
  );
};

/* input type = text */
const TextInput = ({ label, name, placeholder, register, error }) => (
  <div className={"formInput"}>
    <div className={"inputWrap"}>
      <label>{label}</label>
      <input type={"text"} placeholder={placeholder} {...register(name)} />
    </div>
    {error && <p className={"errorMsg"}>{error.message}</p>}
  </div>
);

/* input type = file */
const FileInput = ({ label, name, setValue, error }) => {
  const inputFileRef = useRef(null);
  const [fName, setFName] = useState(null);

  const limitSize = 1024 ** 2 * 5; // 5MB

  const onToggleInputFile = () => {
    inputFileRef.current?.click();
  };

  const onFileSelect = (event) => {
    if (event.target.files && event.target.files[0]) {
      // 업로드한 파일명, 사이즈, 타입 추출
      const { name, size, type } = event.target.files[0];
      // 파일 사이즈 확인
      if (size / 1024 > limitSize) {
        alert("5MB 이하의 파일을 업로드 해주시기 바랍니다");
      }
      setFName(size / 1024 > limitSize ? null : name);
      setValue("file", event.target.files[0]);
    } else {
      if (fName == null) {
        setValue("file", null);
      }
    }
  };

  return (
    <div className={"formInput"}>
      <div className={"inputWrap"}>
        <label>{label}</label>
        <div className={"inputFile"}>
          <input
            type={"file"}
            name={name}
            ref={inputFileRef}
            onChange={onFileSelect}
          />
          <span>{fName || "파일 명"}</span>
          <button type={"button"} onClick={onToggleInputFile}>
            파일 업로드
          </button>
        </div>
      </div>
      {error && <p className={"errorMsg"}>{error.message}</p>}
    </div>
  );
};

const EditorInput = ({ label, name, watch, error, setValue }) => {
  const onEditorChange = (editor, type) => setValue(type, editor.getHTML());
  return (
    <div className={"formInput"}>
      <div className={"inputWrap"}>
        <label>{label}</label>
        <div className={"inputTextArea"}>
          <Editor
            type={name}
            className={"inputEditor"}
            onChange={onEditorChange}
            value={watch(name)}
          />
        </div>
      </div>
      {error && <p className={"errorMsg"}>{error.message}</p>}
    </div>
  );
};

/* input type= checkbox (single) */
const CheckBoxInput = ({ label, checkLabel, name, register, error }) => (
  <div className={"formInput"}>
    <div className={"inputWrap"}>
      <label>{label}</label>
      <div className={"inputCheck"}>
        <label>{checkLabel}</label>
        <input type={"checkbox"} name={name} {...register(name)} />
      </div>
    </div>
    {error && <p className={"errorMsg"}>{error.message}</p>}
  </div>
);

/* input type = checkbox (multiple) */
const CheckBoxListInput = ({ label, items, name, register, error }) => (
  <div className={"formInput"}>
    <div className={"inputWrap"}>
      <label>{label}</label>
      <div className={"checkList"}>
        {items != null &&
          items.map(({ _id, code, label }, i) => (
            <div key={`nft_chb_${_id}`} className={"checkItem"}>
              <input
                type={"checkbox"}
                id={`nft_chb_id_${_id}`}
                name={name}
                value={code}
                {...register(name)}
              />
              <label htmlFor={`nft_chb_id_${_id}`}>{label}</label>
            </div>
          ))}
      </div>
    </div>
    {error && <p className={"errorMsg"}>{error.message}</p>}
  </div>
);

export default NftForm;

import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { accountSelect } from "../../../recoil/AtomConfig";
import { ClientReqGet, ClientReqGet2 } from "../../../utils/ClientAuth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function useUserInfos(errorHandler) {
  const [account, setAccount] = useRecoilState(accountSelect);
  const [customError, setCustomError] = useState(false);
  const navi = useNavigate();
  const toggle = (value) => {
    setAccount((prev) => {
      const temp = { ...prev };
      temp.accessToken = value;
      return { ...temp };
    });
  };
  const query = useQuery(
    "userInfo",
    () =>
      ClientReqGet("/user", account.accessToken, account.refreshToken, toggle)
        .catch((e) => {
          setCustomError(true);
          throw e;
        })
        .then((data) =>
          ClientReqGet(
            `/nftg/my/cnt`,
            account.accessToken,
            account.refreshToken,
            toggle
          ).then((data2) => {
            data.data.nftCnt = data2.data.cnt;
            return data;
          })
        ),
    {
      retry: false,
      onError: (e) => {
        console.log(e);
        if (e.statusCode === 401) {
          alert("로그인이 만료되었습니다. 로그인 페이지로 이동합니다.");
          navi("/signin");
        }
        setCustomError(true);
        return e;
      },
    }
  );

  return { ...query, customError };
}
